<script setup lang="ts">
const { fullWidth = false, applyPadding = false }
  = defineProps<{
    fullWidth?: boolean
    applyPadding?: boolean
  }>()

const maxWidthClass = inject<string>('maxWidthClass', 'max-w-7xl')
</script>

<template>
  <section
    class="relative mx-auto w-full"
    :class="{ [maxWidthClass]: !fullWidth, 'px-4': applyPadding }"
  >
    <slot />
  </section>
</template>
