import { setCssVariable } from '~/utils/setCssVariable'

export default function useTheme() {
  const { tenant } = useTenant()
  const legacyStyle = tenant.value == 'public' // public is only used for nijmegen & meedoen, not for stageplein.

  const appConfig = useAppConfig()

  function updateAppConfigByTenant() {
    appConfig.nui.defaultShapes = appConfig.nui.defaultShapes || {}

    if (tenant.value == 'roermond') {
      appConfig.nui.defaultShapes.accordion = 'straight'
      appConfig.nui.defaultShapes.autocompleteItem = 'straight'
      // appConfig.nui.defaultShapes.avatar = 'rounded' // avatar always rounded
      appConfig.nui.defaultShapes.button = 'straight'
      appConfig.nui.defaultShapes.buttonAction = 'straight'
      appConfig.nui.defaultShapes.buttonIcon = 'straight'
      appConfig.nui.defaultShapes.buttonClose = 'straight'
      appConfig.nui.defaultShapes.card = 'straight'
      appConfig.nui.defaultShapes.dropdown = 'straight'
      appConfig.nui.defaultShapes.iconBox = 'straight'
      appConfig.nui.defaultShapes.input = 'straight'
      appConfig.nui.defaultShapes.message = 'straight'
      appConfig.nui.defaultShapes.pagination = 'straight'
      appConfig.nui.defaultShapes.progress = 'straight'
      appConfig.nui.defaultShapes.prose = 'straight'
      appConfig.nui.defaultShapes.tabSlider = 'straight'
    }
    else {
      appConfig.nui.defaultShapes.accordion = 'rounded'
      appConfig.nui.defaultShapes.autocompleteItem = 'rounded'
      // appConfig.nui.defaultShapes.avatar = 'rounded'
      appConfig.nui.defaultShapes.button = 'rounded'
      appConfig.nui.defaultShapes.buttonAction = 'rounded'
      appConfig.nui.defaultShapes.buttonIcon = 'rounded'
      appConfig.nui.defaultShapes.buttonClose = 'rounded'
      appConfig.nui.defaultShapes.card = 'rounded'
      appConfig.nui.defaultShapes.dropdown = 'rounded'
      appConfig.nui.defaultShapes.iconBox = 'rounded'
      appConfig.nui.defaultShapes.input = 'rounded'
      appConfig.nui.defaultShapes.message = 'rounded'
      appConfig.nui.defaultShapes.pagination = 'rounded'
      appConfig.nui.defaultShapes.progress = 'rounded'
      appConfig.nui.defaultShapes.prose = 'rounded'
      appConfig.nui.defaultShapes.tabSlider = 'rounded'
    }
    updateAppConfig(appConfig)
    // console.log(`[useTheme] updated appConfig`, appConfig)
  }
  updateAppConfigByTenant()

  const defaultShapes = computed(() => appConfig.nui.defaultShapes)

  // Put these here so it applies across all layouts, including the empty one
  const colorConfig = useColorConfig()

  function updateColors(config: ReturnType<typeof useColorConfig>) {
    // console.log(`[useTheme] updateColors`, config)

    // Set the primary color by either using custom color shades or the preset
    if (config.primaryColorShades.value) applyCustomColorShades(config.primaryColorShades.value, 'primary')
    else if (config.primaryColorPreset.value) switchColorPreset('primary', config.primaryColorPreset.value)

    // Set the secondary color by either using custom color shades or the preset
    if (config.secondaryColorShades.value) applyCustomColorShades(config.secondaryColorShades.value, 'secondary')
    // Fallback to primary as the secondary color
    else if (config.secondaryColorPreset.value) switchColorPreset('secondary', config.secondaryColorPreset.value || config.primaryColorPreset.value)
    // Otherwise use a muted color
    else switchColorPreset('secondary', config.mutedColorPreset.value || 'slate')

    // Set the muted color
    switchColorPreset('muted', config.mutedColorPreset.value || 'slate')

    // Set the background color
    // Fallback to the muted-50 color
    setCssVariable('color-theme-background', config.backgroundColor.value || 'rgb(var(--color-muted-50))')
    setCssVariable('color-theme-background-dark', 'rgb(var(--color-muted-950))')

    // Volunteerly legacy theme (nijmegen and meedoen)
    if (tenant.value == 'public') {
      // Base border color
      setCssVariable('color-theme-border', 'rgb(var(--color-muted-200))')
      // Header border is the same as base by default
      setCssVariable('color-theme-header-border', 'rgb(var(--color-muted-200))')
      // Menu border color
      setCssVariable('color-theme-header-border', 'rgb(var(--color-muted-300))')
    }
    else {
      // Base border color
      setCssVariable('color-theme-border', 'rgb(var(--color-secondary-100))')
      // Header border is the same as base by default
      setCssVariable('color-theme-header-border', 'rgb(var(--color-secondary-100))')

      // Volunteerly uses the primary color for the tabs menu (the default)
      // Mas4you and Roermond use the secondary color for the tabs menu
      const useSecondaryColorForTabsMenu = tenant.value == 'mas4you' // || tenant.value == 'roermond'
      // Stageplein uses muted color for the tabs menu
      const useMutedColorForTabsMenu = useAppConfig().app.isStagedoos

      // console.log(`runtimeConfig`, runtimeConfig)
      // console.log(`[useTheme] useSecondaryColorForTabsMenu`, useSecondaryColorForTabsMenu)
      // console.log(`[useTheme] useMutedColorForTabsMenu`, useMutedColorForTabsMenu)

      if (useSecondaryColorForTabsMenu) {
        // Basically Mas4you theme.
        // Menu border color
        setCssVariable('color-theme-header-border', 'rgb(var(--color-secondary-100))')
        // Menu tabs border color
        setCssVariable('color-theme-menu-tabs-border', 'rgb(var(--color-secondary-200))')
        // Tabs background color, slightly darker than the main background
        setCssVariable('color-theme-menu-tabs-background', 'rgb(var(--color-secondary-100)/.5)')
      }
      else if (useMutedColorForTabsMenu) {
        // Stageplein theme
        // Menu border color
        setCssVariable('color-theme-header-border', 'rgb(var(--color-muted-300))')
        // Menu tabs border color
        setCssVariable('color-theme-menu-tabs-border', 'rgb(var(--color-muted-300))')
        // Tabs background color, slightly darker than the main background
        setCssVariable('color-theme-menu-tabs-background', 'rgb(var(--color-muted-100)/.5)')
      }
      else {
        // Volunteerly theme
        // Menu border color
        setCssVariable('color-theme-header-border', 'rgb(var(--color-primary-200)/.8)')
        // Menu tabs border color
        setCssVariable('color-theme-menu-tabs-border', 'rgb(var(--color-primary-200))')
        // Menu tabs background color, slightly darker than the main background
        setCssVariable('color-theme-menu-tabs-background', 'rgb(var(--color-primary-100)/.5)')
      }
    }

    // Set the default text color for content blocks
    // @maud @roos does this make sense?
    setCssVariable('color-theme-content-text', colorConfig?.textColor?.value || 'rgb(var(--color-primary-950))')

    // TODO: move this to a footer component config.
    // Set the footer background color
    // Mas4you also has a custom footer background color, which we hardcoded in the component.
    if (tenant.value == 'oldebroek') {
      setCssVariable('color-theme-footer-background', '#160849')
    }
    else {
      // For roermond, it's the primary color
      setCssVariable('color-theme-footer-background', 'rgb(var(--color-primary-500))')
    }
  }

  // TODO: find a better way to do this.
  // switchColorPreset needs the document, so we need to wait until mounted.
  onBeforeMount(() => {
    updateColors(colorConfig)
    updateAppConfigByTenant()
  })

  watch([colorConfig.primaryColorPreset, colorConfig.secondaryColorPreset, colorConfig.mutedColorPreset, colorConfig.backgroundColor], () => {
    updateColors(colorConfig)
  })

  // TODO:
  // - fix dark mode with custom theme bg color (it keeps showing light mode currently)
  // was bg-secondary-50
  const backgroundColorClass = 'bg-theme-background dark:bg-theme-background-dark'

  // Provide default theme or custom theme for tenant
  provideHeaderTheme({
    // TODO: get this from the theme / header theme config
    sticky: tenant.value == 'oldebroek' || tenant.value == 'roermond',
    cmsMenu: {
      position: legacyStyle ? 'bottom' : 'top',
      hasBorders: legacyStyle ? true : false,
      fontWeight: 'semibold',
    },
    authMenu: {
      style: legacyStyle ? 'legacy' : 'tab',
      backgroundColorClass: 'color-theme-background',
    },
  })

  // For the new design, we have max-w-5xl, while for Volunteerly the default is max-w-7xl.
  // @roos @maud not sure what to do here.
  // provide('maxWidthClass', 'max-w-7xl')
  provide('maxWidthClass', 'max-w-7xl')

  // @maud @roos
  // TODO: should we differentiate between inner and outer content block padding?
  // The default "inner content block" padding that is used in several content blocks, like TextWithUsps block.
  provide('contentBlockPadding', 'p-8 md:p-10')

  // The spacing between content blocks
  // TODO: provide this one as well?
  const contentBlockSpacing = 'space-y-6'
  provide('contentBlockSpacing', contentBlockSpacing)

  // The default size for the content block title
  provide('contentBlockTitleSize', '4xl')

  return {
    backgroundColorClass,
    contentBlockSpacing,
    defaultShapes,
  }
}
