export interface VolunteerCenter extends Omit<Organization, 'status'> {
  municipality: string
}

export interface VolunteerCenterProfile {
  websiteLogoUrl?: string
  websiteLogoDarkModeUrl?: string
  faviconUrl?: string
  logoMarkUrl?: string
  logoMarkDarkModeUrl?: string
  municipality?: string
  primaryColor?: string // color preset name
  primaryColorShades?: string // custo color shades config
  secondaryColor?: string // color preset name
  secondaryColorShades?: string // custom color shades config
  mutedColor?: string // color preset name
  backgroundColor?: string // hex code
  textColor?: string // hex code
  facebookUrl?: string
  footerColumn1Title?: string
  footerColumn2Title?: string
  footerColumn3Title?: string
  footerColumn4Title?: string
  footerColumn6Title?: string
  footerColumn6Content?: string
  footerColumn8Title?: string
  footerColumn8Content?: string
  showAnbiLogo?: boolean
  showNovLogo?: boolean
  instagramUrl?: string
  mastodonUrl?: string
  tiktokUrl?: string
  xUrl?: string
  gtagId?: string
}

export async function fetchVolunteerCenters() {
  const {
    attributes: volunteerCenters,
    pending,
    refresh,
    data,
  } = await useApiFetch<VolunteerCenter[]>('/api/v1/volunteer_centers')

  return {
    volunteerCenters,
    pending,
    refresh,
    data,
  }
}

export async function fetchCurrentVolunteerCenter() {
  return await $api<VolunteerCenter & { profile: VolunteerCenterProfile }>(
    '/api/v1/volunteer_centers/current?include=profile',
    {
      deserialize: true,
    },
  )

  // return {
  //   volunteerCenter,
  //   relationships,
  //   pending,
  //   refresh,
  //   data,
  // }
}
