export interface HeaderTheme {
  // Is the header sticky?
  sticky: boolean
  // These are used for the menu items
  text: {
    default: {
      light: string // e.g. 'muted-400'
      dark: string // e.g. 'muted-500'
    }
    hover: {
      light: string // e.g. 'muted-500'
      dark: string // e.g. 'muted-400'
    }
    active: {
      light: string // e.g. 'muted-900'
      dark: string // e.g. 'muted-100'
    }
  }
  // Used for the active indicator of the menu items
  accent: string // e.g. 'primary-500' - for active indicators
  cmsMenu: {
    // Position of the CMS menu inside the header
    position: 'top' | 'bottom'
    hasBorders: boolean
    fontWeight: 'normal' | 'semibold'
  }
  authMenu: {
    style: 'tab' | 'legacy'
    // TODO: get rid of this
    backgroundColorClass: string
  }
}

const defaultTheme: HeaderTheme = {
  sticky: false,
  text: {
    default: {
      light: 'muted-800',
      dark: 'muted-100',
    },
    hover: {
      light: 'muted-800',
      dark: 'muted-400',
    },
    active: {
      light: 'muted-800',
      dark: 'muted-100',
    },
  },
  accent: 'primary-500',
  cmsMenu: {
    position: 'bottom',
    hasBorders: true,
    fontWeight: 'normal',
  },
  authMenu: {
    style: 'tab', // 'tab' or 'legacy',
    backgroundColorClass: 'color-theme-background',
  },
}

const headerThemeKey = Symbol('header-theme') as InjectionKey<Ref<Partial<HeaderTheme>>>

export function provideHeaderTheme(theme: Partial<HeaderTheme> = {}) {
  const injectedTheme = ref(theme)
  provide(headerThemeKey, injectedTheme)
  return injectedTheme
}

export function useHeaderStyles() {
  const injectedTheme = inject(headerThemeKey, ref({}))

  const theme = computed(() => ({
    ...defaultTheme,
    ...injectedTheme.value,
  }))

  // Computed classes that components can use directly
  const classes = computed(() => ({
    menuItem: {
      base: `text-${theme.value.text.default.light} dark:text-${theme.value.text.default.dark}
             hover:text-${theme.value.text.hover.light} dark:hover:text-${theme.value.text.hover.dark}
             border-transparent`,
      active: `!border-${theme.value.accent} 
               !text-${theme.value.text.active.light} dark:!text-${theme.value.text.active.dark}`,
    },
  }))

  return {
    theme,
    classes,
  }
}
