import revive_payload_client_ScDZvtwihP from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4_encoding@0.1.13_eslint@8.57.0_ioredis@5_r3duii7ahgkplnggkcj6d6mvlm/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_g88fMNVysI from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4_encoding@0.1.13_eslint@8.57.0_ioredis@5_r3duii7ahgkplnggkcj6d6mvlm/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DlpzK3u3Xc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4_encoding@0.1.13_eslint@8.57.0_ioredis@5_r3duii7ahgkplnggkcj6d6mvlm/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_BLu5uJyb1F from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4_encoding@0.1.13_eslint@8.57.0_ioredis@5_r3duii7ahgkplnggkcj6d6mvlm/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xSCx9ZaFsr from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4_encoding@0.1.13_eslint@8.57.0_ioredis@5_r3duii7ahgkplnggkcj6d6mvlm/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_b4ongJSzdU from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4_encoding@0.1.13_eslint@8.57.0_ioredis@5_r3duii7ahgkplnggkcj6d6mvlm/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_df9SiJr2o0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4_encoding@0.1.13_eslint@8.57.0_ioredis@5_r3duii7ahgkplnggkcj6d6mvlm/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_O7KgtBwogM from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.5_rollup@4.34.8_typescript@5.4.5_vue@3.5.12_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/volunteerly/.nuxt/components.plugin.mjs";
import prefetch_client_3p0YY1QDzp from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.13.4_encoding@0.1.13_eslint@8.57.0_ioredis@5_r3duii7ahgkplnggkcj6d6mvlm/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_1SoM5kyPfP from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.3.2_magicast@0.3.5_rollup@4.34.8_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.mjs";
import plugin_f1No3XQNtd from "/vercel/path0/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_magicast@0.3.5_rollup@4.34.8_vue@3.5.12_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_oS1oT0aPIL from "/vercel/path0/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import plugin_client_R75kFyyxMi from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@2.0.6_patch_hash=zfnymi2b2itc6bxqlfbjljgqim_magicast@0.3.5_rollup@4.34.8_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.mjs";
import i18n_7dO6QayE2r from "/vercel/path0/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.5_rollup@4.34.8_vue@3.5.12_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_iGNyZjkxCR from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_magicast@0.3.5_rollup@4.34.8_typesc_3ksv43nq355msyf2inmut75ssq/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import directives_yBPzJQtdia from "/vercel/path0/layers/tairo/plugins/directives.ts";
import default_locale_U9KBtXyBIy from "/vercel/path0/.app/plugins/default-locale.ts";
import dompurify_CKiVSAlvqt from "/vercel/path0/.app/plugins/dompurify.ts";
import yup_1KuivxImeD from "/vercel/path0/.app/plugins/yup.ts";
export default [
  revive_payload_client_ScDZvtwihP,
  unhead_g88fMNVysI,
  router_DlpzK3u3Xc,
  payload_client_BLu5uJyb1F,
  navigation_repaint_client_xSCx9ZaFsr,
  check_outdated_build_client_b4ongJSzdU,
  chunk_reload_client_df9SiJr2o0,
  plugin_vue3_O7KgtBwogM,
  components_plugin_KR1HBZs4kY,
  prefetch_client_3p0YY1QDzp,
  plugin_client_1SoM5kyPfP,
  plugin_f1No3XQNtd,
  plugin_oS1oT0aPIL,
  plugin_client_R75kFyyxMi,
  i18n_7dO6QayE2r,
  plugin_iGNyZjkxCR,
  directives_yBPzJQtdia,
  default_locale_U9KBtXyBIy,
  dompurify_CKiVSAlvqt,
  yup_1KuivxImeD
]