/**
 * Returns the volunteer center of the website that the user is currently visiting.
 * Maybe this composable is a bit overkill if `fetchCurrentVolunteerCenter` also caches properly.
 * This whole thing is a bit reduntant I guess. But it seems to work.
 */
export const useCurrentCenter = () => {
  // Non-null is technically possible, but we're fetching it in the middleware so this should be sage.
  const currentCenter = useState<VolunteerCenter>('current-center')
  const currentCenterProfile = useState<VolunteerCenterProfile>('current-center-profile')

  async function fetch() {
    // if (!currentCenter.value || !currentCenterProfile.value) {
    // fetchCurrentVolunteerCenter doesn't need the ID, because it's set on the header already.

    const initialCenter = await fetchCurrentVolunteerCenter()

    // console.log(`[useCurrentCenter] fetched initial center`, initialCenter)

    currentCenter.value = initialCenter
    currentCenterProfile.value = initialCenter?.profile
  }

  const isHeumen = computed(() => currentCenter.value?.name.includes('Heumen'))

  return { currentCenter, currentCenterProfile, isHeumen, fetch }
}
