// Make it possible to set the tenant via query param in dev mode
export default defineNuxtRouteMiddleware(async (to) => {
  if (!import.meta.env.DEV) return

  const { tenant } = useTenant()
  const tenantQuery = to.query.tenant as string

  if (tenantQuery) {
    tenant.value = tenantQuery
  }
})
