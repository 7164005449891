export default function useColorConfig() {
  const { currentCenterProfile } = useCurrentCenter()

  // ## Roermond theme
  // Background color: #f3fafa
  // Primary color: #1A3336
  // Secondary color: #16B0A5

  // From Swipe's website:
  // --e-global-color-primary: #1A3336;
  // --e-global-color-secondary: #16B0A5;
  // --e-global-color-text: #FABC32;
  // --e-global-color-accent: #F19DA0;
  // --e-global-color-53042d1: #BB90BD;
  // --e-global-color-5992c10: #1A3336;
  // --e-global-color-367b527: #1A3336;
  // --e-global-color-65f30c1: #16B0A5;
  // --e-global-color-8bc429b: #F3FAFA;
  // --e-global-color-b771b2c: #FABC32;
  // --e-global-color-06d7009: #1CBCB0;

  // Muted shades: default (shouldn't be used eventually)
  // These are configured through the design page:
  // const roermondPrimaryShades = `{
  // "50": "#E3F0F2",
  // "100": "#C4E0E3",
  // "200": "#8AC1C7",
  // "300": "#51A0A9",
  // "400": "#376C72",
  // "500": "#1A3336",
  // "600": "#162A2D",
  // "700": "#0F1D1F",
  // "800": "#0A1415",
  // "900": "#050A0A",
  // "950": "#030707"
  // }`

  // const roermondSecondaryShades = `{
  // "50": "#E4FCFA",
  // "100": "#C9F8F5",
  // "200": "#92F1EB",
  // "300": "#57EAE0",
  // "400": "#21E3D6",
  // "500": "#16B0A5",
  // "600": "#128D84",
  // "700": "#0D6862",
  // "800": "#094944",
  // "900": "#052422",
  // "950": "#021211"
  // }`

  // ## Oldebroek theme
  // {
  //   "50": "#EFEBFA",
  //   "100": "#DDD2F4",
  //   "200": "#BAA5E9",
  //   "300": "#9B7CDF",
  //   "400": "#794FD4",
  //   "500": "#5B2EBC",
  //   "600": "#492598",
  //   "700": "#381C73",
  //   "800": "#24124A",
  //   "900": "#120925",
  //   "950": "#0A0514"
  // }

  // console.log(`[useColorConfig] currentCenter`, currentCenter.value)

  // Temp flag for testing
  const useCenterProfileForColors = computed(() => true)

  // Name of the primary color present
  const primaryColorPreset = computed(() => useCenterProfileForColors.value ? currentCenterProfile.value?.primaryColor : undefined)
  // Custom primary color shades
  const primaryColorShades = computed(() => useCenterProfileForColors.value ? currentCenterProfile.value?.primaryColorShades : undefined)
  // Name of the secondary color present
  const secondaryColorPreset = computed(() => useCenterProfileForColors.value ? currentCenterProfile.value?.secondaryColor : undefined)
  // Custom secondary color shades
  const secondaryColorShades = computed(() => useCenterProfileForColors.value ? currentCenterProfile.value?.secondaryColorShades : undefined)
  // Name of the muted color present
  const mutedColorPreset = computed(() => useCenterProfileForColors.value ? currentCenterProfile.value?.mutedColor : undefined)

  // Hex code instead of preset name!
  const backgroundColor = computed(() => useCenterProfileForColors.value ? currentCenterProfile.value?.backgroundColor : undefined)

  // TODO: contentTextColor or textColor?
  // TODO: add field on volunteer center profile
  const textColor = computed(() => useCenterProfileForColors.value ? currentCenterProfile.value?.textColor : undefined)

  // console.log(`[useColorConfig] colorShades`, colorShades.value)
  // console.log(`[useColorConfig] primaryColorPreset`, primaryColorPreset.value)
  // console.log(`[useColorConfig] primaryColorShades`, primaryColorShades.value)
  // console.log(`[useColorConfig] secondaryColorPreset`, secondaryColorPreset.value)
  // console.log(`[useColorConfig] secondaryColorShades`, secondaryColorShades.value)
  // console.log(`[useColorConfig] mutedColorPreset`, mutedColorPreset.value)
  // console.log(`[useColorConfig] backgroundColor`, backgroundColor.value)

  return {
    primaryColorShades,
    primaryColorPreset,
    secondaryColorPreset,
    secondaryColorShades,
    mutedColorPreset,
    backgroundColor,
    textColor,
  }
}
