// Note: not full reactive, it's a mix of computed values and runtime config
export default function useTenantConfig() {
  const isMeedoen = useRuntimeConfig().public.isMeedoen

  const { tenant } = useTenant()

  const isOldebroek = computed(() => tenant.value == 'oldebroek')
  const isRoermond = computed(() => tenant.value == 'roermond')
  const isPublicTenant = computed(() => tenant.value == 'public')

  const tenantAppNames = {
    oldebroek: 'Vrijwilligersvacaturebank Oldebroek',
    roermond: 'Vrijwilligerspunt Roermond',
    meedoen: 'Ikdoemee.nl',
  }

  return {
    tenant: tenant.value,
    // Is this tenant using volunteer profiles?
    volunteerProfilesEnabled: !isMeedoen,
    // Is this tenant using activities?
    // Currently disabled for Meedoen and Oldebroek
    activitiesEnabled: !isMeedoen && !isOldebroek.value,
    // Is p2p enabled?
    // Currently disabled for Roermmond, Oldebroek and Meedoen
    p2pEnabled: isPublicTenant.value,
    // Is this tenant using activity types for the social map / organization search?
    organizationActivityTypesEnabled: !isMeedoen,
    // Is this tenant using volunteer profiles?
    isUsingVolunteerProfiles: !isMeedoen,
    // Tenant app name - in case the app name is not determined by the current center
    // If this is nil, the center name will be used as the app name
    tenantAppName: tenantAppNames[tenant.value as keyof typeof tenantAppNames] as string | undefined,
    // Whether center sharing is enabled
    centerSharingEnabled: tenant.value == 'public' && !isMeedoen,
    // Use i18n?
    // Disabled for Roermond and Oldebroek, for now, until we've improved i18n.
    i18nEnabled: tenant.value == 'public' || isMeedoen,
  }
}
