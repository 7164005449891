export const useDefaultHeaders = () => {
  const centerId = useCurrentCenterId()
  const runtimeConfig = useRuntimeConfig()
  const { selectedOrganization } = storeToRefs(useCurrentOrganizationStore())

  const { tenant } = useTenant()

  return computed(() => {
    const h: Record<string, string> = {
      'X-Tenant': tenant.value,
      'App-Context': runtimeConfig.public.isMeedoen ? 'meedoen' : 'gelderland',
      'Volunteer-Center-Id': centerId.value as string,
    }

    if (selectedOrganization.value) {
      h['Current-Organization-Id'] = selectedOrganization.value?.id as string
    }

    return h
  })
}
