interface TenantConfig {
  default: string
  domains: Record<string, string>
  wildcardDomains?: string[]
}

export default function useTenant() {
  // Use cookie name from nuxt config, or fallback to 'tenant'
  // This is mainly to avoid clashes in development on localhost.
  const tenantCookie = useRuntimeConfig().public.tenants.cookie || 'tenant'

  const tenant = useCookie<string>(tenantCookie, {
    secure: true,
    watch: true,
  })

  function setInitialTenant() {
    const requestUrl = useRequestURL()
    const host = requestUrl.host
    const publicConfig = useRuntimeConfig().public
    const tenantsConfig = publicConfig.tenants as TenantConfig
    const defaultTenant = tenantsConfig.default

    // Check if we're in development mode and a tenant is set via query param
    // If this works well, we can delete the middleware.
    if (import.meta.env.DEV) {
      const tenantParam = requestUrl.searchParams.get('tenant')
      if (tenantParam) {
        // console.log('[useTenant] Using tenant from query param:', tenantParam)
        tenant.value = tenantParam
        return
      }
    }

    // First check if there's an explicit tenant configuration for this host
    if (tenantsConfig.domains && host in tenantsConfig.domains) {
      tenant.value = tenantsConfig.domains[host]
      return
    }

    // Check for wildcard domains from config
    if (tenantsConfig.wildcardDomains) {
      for (const wildcardDomain of tenantsConfig.wildcardDomains) {
        if (host.endsWith(wildcardDomain)) {
          const subdomain = host.split('.')[0]
          if (subdomain) {
            tenant.value = subdomain
            return
          }
        }
      }
    }

    // Fall back to default tenant if no match found, and tenant cookie is not set
    if (!tenant.value) {
      tenant.value = defaultTenant
    }
  }

  setInitialTenant()

  if (!tenant.value) {
    console.error('[useTenant] No tenant found')
    throw new Error('[useTenant] No tenant found')
  }

  return { tenant }
}
