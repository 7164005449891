export default function useAppName() {
  const { tenantAppName } = useTenantConfig()

  // For Nijmegen, we use the center name as the app name
  // For most other tenants, we use a name that we configure on the tenant config
  // (and that future tenants will be able to set themselves)
  // If this is nil, the center name will be used as the app name
  const appName = computed(() => {
    // `import.meta.client` is to avoid a nuxt error when using this in middleware
    if (import.meta.client && !tenantAppName) {
      const { currentCenter } = useCurrentCenter()
      console.log(`[useAppName] currentCenter:`, currentCenter.value?.name)
      return currentCenter.value?.name
    }

    return tenantAppName
  })

  return {
    appName,
  }
}
