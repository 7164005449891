import { defu } from 'defu'
import { deserializeJsonApiData } from './useJsonApiFetch'
import { type NitroFetchRequest } from 'nitropack'

// const logsEnabled = false

// Source: https://stackoverflow.com/questions/73354780/nuxt-3-how-to-add-default-parameters-and-headers-to-fetch
// Throws an error on failed requests.
export function $api<
  T,
  R extends NitroFetchRequest = NitroFetchRequest,
>(
  request: Parameters<typeof $fetch<T, R>>[0],
  options?: Partial<Parameters<typeof $fetch<T, R>>[1]> & {
    deserialize?: boolean // Optional parameter to enable JSON:API deserialization
    asFormData?: boolean // Optional parameter to transform the body to form data
  },
) {
  const config = useRuntimeConfig()
  const defaultHeaders = useDefaultHeaders()
  const { authHeaders, handleAuthResponseHeaders } = useAuth()
  const { addBreadcrumb } = useSentry()

  // Add a breadcrump for every request
  // Move to a dedicated composable
  function addRequestBreadcrumb(authHeaders: Ref<Record<string, string>>) {
    const hasToken = !!authHeaders.value['access-token']
    addBreadcrumb({
      category: '$api request',
      message: `Has token: ${hasToken}`,
      level: 'info',
    })
  }

  // Extract jsonApi option and remove it from options to avoid passing it to $fetch
  const { deserialize, asFormData, ...fetchOptions } = options || {}

  return $fetch<T, R>(request, {
    baseURL: config.public.railsApiURL,
    ...fetchOptions,
    onRequest: ({ options }) => {
      options.headers = defu({
        ...defaultHeaders.value,
        ...options.headers,
        ...authHeaders.value,
      })

      addRequestBreadcrumb(authHeaders)

      if (options.body) {
        // For POST and UPDATE requests, we have this utility to transform the body to form data,
        // for handling file uploads. We probably want to get rid of this, maybe just use base64 for
        // file uploads.
        if (asFormData) {
          options.body = objectToFormData(options.body, new FormData())
        }
        else {
          options.body = convertKeysToSnakeCase(options.body)
        }
      }
    },

    onResponse({ response, options }) {
      if (response._data) {
        // Only deserialize if deserialize option is true
        if (deserialize && response._data.data) {
          const data = convertKeysToCamelCase(response._data)

          // Deserialize the JSON:API response
          const deserializedData = deserializeJsonApiData(data)
          // We could keep it wrapped in a data property, but I wasn't sure if that's really useful.
          // response._data = { data: deserializedData }
          // Instead, just return the deserialized data.
          response._data = deserializedData
        }
        else {
          // Legacy behavior - no deserialization
          // First convert the data to camelCase
          response._data = convertKeysToCamelCase(response._data)
        }
      }

      // Note: this has some overlap with useApiFetch
      // I discovered that even though the API shouldn't refresh the token on every request, it
      // still does this for certain requests. Mainly POST requests? I'm not sure.
      if (response.headers && !!response.headers.get('access-token')) {
        // logDevTokens(response)
        handleAuthResponseHeaders(response.headers)
      }
    },
  })
}

// function logDevTokens(response: Response) {
//   // The main issue we were having with the tokens was that the API would return old tokens
//   // after signout. There's a fix for that in useApiFetch. The same fix causes issues with $api,
//   // due to authentication requests. So we're not using it here.
//   //
//   if (import.meta.env.DEV) {
//     try {
//       const requestHadAccessToken
//         = options.headers && (options.headers as any).get('access-token')

//       if (!requestHadAccessToken) {
//         console.error(
//           `[$api] Received a token while the request didn't have one. (Is this a signin request?)`,
//           requestHadAccessToken,
//           options,
//           options.headers,
//         )
//       }
//     }
//     catch (error) {
//       console.error(error)
//     }
//   }
// }
