// Copied from the Tairo demo app
import chroma from 'chroma-js'
import { setCssVariable } from './setCssVariable'

// TODO: add secondary
export type ColorName = 'primary' | 'secondary' | 'muted'
export type ColorShade =
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900'
  | '950'

const shades = [
  '50',
  '100',
  '200',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
  '950',
] as const

const EMPTY_COLOR = '0 0 0'

/**
 * Convert color to RGB values, for use with Tailwind CSS variables.
 */
export const colorToRgb = (color: string): string => {
  if (!color) {
    return EMPTY_COLOR
  }

  return chroma(color)
    .rgb()
    .map((v) => {
      if (Number.isNaN(v)) {
        return 0
      }

      return Math.round(v)
    })
    .slice(0, 3) // Remove alpha channel if present
    .join(' ')
}

/**
 * Set a tailwind color complete shade by preset name.
 * Throws an error if the preset is not found.
 */
export function switchColorPreset(name: ColorName, presetName: string) {
  const presets = name == 'muted' ? mutedPresets : colorPresets

  const preset = presets.find(preset => preset.name === presetName)
  if (!preset) {
    // throw new Error(`Color preset ${presetName} not found.`)
    console.error(`Color preset ${presetName} not found.`)
    return
  }
  switchColorShades(name, preset.shades)
}

/**
 * Set a tailwind color complete shade.
 */
export function switchColorShades(
  name: ColorName,
  shades: Record<ColorShade, string>,
) {
  Object.entries(shades).forEach(([shade, color]) => {
    switchColor(name, shade as ColorShade, color)
  })
}

/**
 * Set a single tailwind color shade from a hex value.
 * TODO: make this work with SSR. (https://app.clickup.com/9004127457/v/l/8cb0571-301)
 * We need to somehow make sure that the color is set on some document element, without using window.
 */
export function switchColor(name: ColorName, shade: ColorShade, color: string) {
  const rgb = colorToRgb(color)
  window.document.documentElement.style.setProperty(
    `--color-${name}-${shade}`,
    rgb,
  )
}

/**
 * Reset all shades of a color.
 */
export function resetColor(name: ColorName) {
  for (const shade of shades) {
    window.document.documentElement.style.removeProperty(
      `--color-${name}-${shade}`,
    )
  }
}

// This function parses a color shades string provided by the user.
export function parseColorShades(
  input: string,
): Record<ColorShade, string> | null {
  try {
    const parsed = JSON.parse(input) as Record<ColorShade, string>

    // Validate the parsed object. Ensure all shades are present.
    for (const shade of shades) {
      if (!parsed[shade]) {
        console.error(`Missing color for shade: ${shade}`)
        return null
      }
    }

    return parsed
  }
  catch (e) {
    console.error('Failed to parse color shades:', e)
    return null
  }
}

// This function can be called to apply the provided color shades.
export function applyCustomColorShades(input: string, colorName: ColorName) {
  const shades = parseColorShades(input)
  if (shades) {
    switchColorShades(colorName, shades)
  }
}
