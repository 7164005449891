import { deserialise, deattribute } from 'kitsu-core'
import { useFetch } from '#imports'
import { defu } from 'defu'
import { getFetchOptions } from './useApiFetch'

// Check to determine if a `data` field is part of a JSON:API relationship (so we can safely exclude it)
// @ts-ignore any type for now
function isJsonApiRelationship(obj: any): boolean {
  return obj && typeof obj === 'object' && 'data' in obj
    && (Array.isArray(obj.data) || (obj.data && 'type' in obj.data && 'id' in obj.data))
}

// This removes the 'data' wrapper around the response object, including nested/included objects
// @ts-ignore any type for now
function unnestRelationships(obj: any, visited = new WeakMap()): any {
  if (Array.isArray(obj)) {
    return obj.map(item => unnestRelationships(item, visited))
  }
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }

  // Check if we've already visited this object
  if (visited.has(obj)) {
    return visited.get(obj)
  }

  const result: any = { ...obj }

  // Mark this object as visited
  visited.set(obj, result)

  for (const key in result) {
    if (isJsonApiRelationship(result[key])) {
      result[key] = unnestRelationships(result[key].data, visited)
    }
    else {
      result[key] = unnestRelationships(result[key], visited)
    }
  }

  return result
}

// TODO: figure out how to pass the shape of the meta object without breaking the type inference of useJsonApiFetch
type Meta = Record<string, any>

// Add _meta as a property to the deserialized object
export type WithMeta<T> = T extends Array<infer U> ? (T & { _meta: Meta }) : (T & { _meta: Meta })

export function wrapWithMeta<T>(data: T, meta: Meta): WithMeta<T> {
  // console.log('[wrapWithMeta] data', data)
  if (Array.isArray(data)) {
    // Create a new array with the meta property instead of modifying the existing one
    const arrayWithMeta = [...data]
    Object.defineProperty(arrayWithMeta, '_meta', {
      value: meta,
      enumerable: true,
      configurable: true,
    })
    return arrayWithMeta as WithMeta<T>
  }
  else {
    return { ...data, _meta: meta } as WithMeta<T>
  }
}

export function deserializeJsonApiData<T>(data: any): WithMeta<T> {
  const deserialised = deserialise(data)
  const transformed = deattribute(deserialised).data
  const unnestTransformed = unnestRelationships(transformed) as T
  const meta = data.meta || {}
  // if (!data.meta) console.warn('[deserializeJsonApiData] no meta found in response, data:', data)
  return wrapWithMeta(unnestTransformed, meta)
}

// WARNING: don't depend on Meta when using this function server-side.
// The meta doesn't get hydrated.
// TODO: fix the return type: wrapping T in WithMeta<T> is what we want to achieve
export function useJsonApiFetch<T>(url: string, options?: MaybeRef<any>) {
  const defaultOptions = getFetchOptions<T>(url)

  // Use unjs/defu for nice deep defaults
  const mergedOptions = defu({}, unref(options), defaultOptions)

  // Keep the query reactive, but mergedOptions non-reactive.
  const query = ref(unref(options)?.query) || {}

  return useFetch<WithMeta<T>>(url, {
    ...mergedOptions,
    query,
    headers: {
      // @ts-ignore (options has type any, which is causing type issues. I'm too lazy to fix that.)
      ...mergedOptions.headers,
      'Accept': 'application/vnd.api+json',
      'Content-Type': 'application/vnd.api+json',
    },
    // Nuxt expects the return type to be the same as the response type, which is not the case here.
    // @ts-ignore
    transform: (data) => {
      if (data) {
        return deserializeJsonApiData<T>(data) as any
      }
      return wrapWithMeta(data, {})
    },
  })
}
