// Deleted is currently not stored as a status, but as a deleted_at timestamp
export enum OrganizationStatus {
  Approved = 'approved',
  Pending = 'pending',
  Denied = 'denied',
  Archived = 'archived',
}

export interface Organization extends ApiEntity {
  type: string
  name: string
  logoUrl?: string
  headerImageUrl?: string
  website?: string
  phoneNumber?: string
  description?: string
  formattedAddress?: string
  street?: string
  houseNumber?: string
  postcode?: string
  city?: string
  country?: string
  latitude?: number
  longitude?: number
  status?: OrganizationStatus // TODO: why can this be undefined?
  importData?: string
  managedByOrganizationIds?: id[]
  profileColor?: string
}

// Used to extend other types with an organization property
export interface WithOrganization {
  organization: Organization
}

// P is the type of the profile, e.g. VolunteerCenterProfile
interface OrganizationRelationships<P> {
  profile: P
  address: Address
}

// Fetch all of the organizations the user belongs to
export async function fetchUserOrganizations() {
  return $api<JSONAPIResponse<Organization[]>>('/api/v1/current_organizations')
}

// Fetch the organization by ID, including address & profile
// P is the type of the profile, e.g. VolunteerCenterProfile
export async function fetchCurrentOrganization<P>() {
  const {
    attributes: organization,
    refresh,
    pending,
    relationships,
  } = await useApiFetch<Organization, OrganizationRelationships<P>>(
    `/api/v1/current_organization?include=address,profile`,
  )

  const profile = computed(() => relationships.value?.profile)

  return {
    organization,
    relationships,
    profile,
    refresh,
    pending,
  }
}

export async function fetchOrganizations(query?: SearchParameters) {
  const {
    attributes: organizations,
    data,
    pending,
    error,
  } = await useApiFetch<Organization[]>('/api/v1/organizations?include=vacancies', { query })

  return {
    organizations,
    data,
    pending,
    error,
  }
}

// Fetch the organization by ID, including address & profile
// P is the type of the profile, e.g. VolunteerCenterProfile
export async function fetchOrganization<P>(id: id) {
  const {
    attributes: organization,
    refresh,
    pending,
    relationships,
  } = await useApiFetch<Organization, OrganizationRelationships<P>>(
    `/api/v1/organizations/${id}?include=profile`,
  )

  const profile = computed(() => relationships.value?.profile)

  return {
    organization,
    refresh,
    pending,
    profile,
    relationships,
  }
}

export async function fetchOrganizationAdmins(id: id) {
  const response = await $api<JSONAPIResponse<User[]>>(`/api/v1/users/`, {
    query: {
      'filter[organization_id_eq]': id,
      'filter[with_role]': 'admin',
    },
  })

  // Quickix alternate solution for lack of extracting attributes in $api,
  // like we have in useApiFetch. See notes in `extractAttributes`
  // TODO: fix typing
  const admins = response.data.map(item => item.attributes)

  return { admins, ...response }
}

export function lazyFetchOrganizationAdmins(id: id) {
  return useLazyApiFetch<User[]>(`/api/v1/users/`, {
    query: {
      'filter[organization_id_eq]': id,
      'filter[with_role]': 'admin',
      'page[size]': 1000,
    },
  })
}

export async function approveOrganization(
  id: string | number,
  status: Organization['status'],
) {
  const { data: organization } = await $api<JSONAPIResponse<Organization>>(
    `/api/v1/organizations/${id}/approve`,
    {
      method: 'PUT',
      body: {
        data: {
          type: 'organizations',
          id,
          attributes: {
            status,
          },
        },
      },
    },
  )

  return organization
}

export async function updateOrganizationStatus(id: id, status: Organization['status']) {
  await $api<JSONAPIResponse<BaseVacancy>>(`/api/v1/organizations/${id}`, {
    method: 'PUT',
    body: {
      data: {
        type: 'organizations',
        id,
        attributes: {
          status: status,
        },
      },
    },
  })
}

export async function deleteOrganization(id: id) {
  await $api<JSONAPIResponse<BaseVacancy>>(`/api/v1/organizations/${id}`, {
    method: 'DELETE',
  })
}
